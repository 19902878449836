.dark{
    .task_board{
        background-blend-mode: darken;
        background-color: #0000004d !important;
    }
    .top_list{
        background-color: #101204;
    }
    .hover\:bg-white{
        &:hover{
            background-color: #262932;
        }
    }
}
