.sidebar_wrapper{
    .text, .name, .arrow, .logo{
        color: #ffffff;
    }
    .side_p_list{
        .item{
            color: #ffffff;
            &.active{
                background-color: #ffffff4d;
            }
            &:hover{
                background-color: #ffffff29;
            }
        }

    }
    ul{
        &.items{
            li{
                a{
                    color: #ffffff;
                    &:hover{
                        background-color: #ffffff29;
                    }
                    svg{
                        fill: #ffffff;
                    }
                }
            }
        }
    }
    .add__plus{
        svg{
            color: #ffffff;
            fill: #ffffff;
        }
        &:hover{
            background-color: #a6c5e229;
            color: #ffffff;
        }
    }
}
.project__loading{
    @apply p-3 animate-pulse rtl:space-x-reverse gap-3 flex items-center space-y-0;
    .p__image{
        @apply flex items-center justify-center rounded-full bg-gray-300 dark:bg-gray-700;
        svg{
            @apply w-5 h-5 rounded-full text-gray-200 dark:text-gray-600;
        }
    }
    .__bar{
        @apply w-full flex flex-col pr-2;
        div{
            @apply h-2 w-full rounded-full bg-gray-200 dark:bg-gray-700;
        }
    }
}
.dark{
    &.layout-app{
        &.main{
            .sidebar{
                &.sidebar_wrapper{
                    .text, .name, .arrow, .logo{
                        color: #ffffff;
                    }
                    .side_p_list{
                        .item{
                            color: #ffffff;
                            &.active{
                                background-color: #ffffff4d;
                            }
                            &:hover{
                                background-color: #ffffff29;
                            }
                        }

                    }
                    ul{
                        &.items{
                            li{
                                a{
                                    color: #ffffff;
                                    &:hover{
                                        background-color: #ffffff29;
                                    }
                                    svg{
                                        fill: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                    .add__plus{
                        svg{
                            color: #ffffff;
                            fill: #ffffff;
                        }
                        &:hover{
                            background-color: #a6c5e229;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

