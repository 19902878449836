//@import "qalendar/dist/style.css";
.calendar-root[data-v-67f77862],.date-picker-root[data-v-67f77862]{
    --qalendar-blue: rgb(38 132 255);
    --qalendar-blue-transparent: rgba(38 132 255 / 90%);
    --qalendar-gray-quite-dark: rgb(110 110 110);
    --qalendar-gray: rgb(180 180 180);
    --qalendar-green: rgb(51 182 121);
    --qalendar-theme-color: var(--qalendar-blue);
    --qalendar-light-gray: rgba(240 236 236 / 76%);
    --qalendar-option-hover: var(--qalendar-light-gray);
    --qalendar-dark-mode-elevated-surface: #383838;
    --qalendar-dark-mode-lightly-elevated-surface: #2e2e2e;
    --qalendar-dark-mode-text-primary: rgba(255 255 255 1);
    --qalendar-dark-mode-text-secondary: rgba(255 255 255 .7);
    --qalendar-dark-mode-text-hint: rgba(255 255 255 .5);
    --qalendar-dark-mode-line-color: var(--qalendar-gray);
    --qalendar-border-gray-thin: 1px solid rgb(224 224 224);
    --qalendar-border-dashed-gray-thin: 1px dashed rgb(224 224 224);
    --qalendar-border-blue-thin: 1px solid var(--qalendar-theme-color);
    --qalendar-border-radius: 8px;
    --qalendar-spacing: 10px;
    --qalendar-spacing-half: 5px;
    --qalendar-spacing-double: 20px;
    --qalendar-box-shadow: 0 4px 4px rgba(0 0 0 / 6%), 0 1px 4px rgba(0 0 0 / 18%);
    --qalendar-text-transition: color .2s ease;
    --qalendar-week-padding-left: 56px;
    --qalendar-font-3xs: .625rem;
    --qalendar-font-2xs: .688rem;
    --qalendar-font-xs: .75rem;
    --qalendar-font-s: .875rem;
    --qalendar-font-m: 1rem;
    --qalendar-font-l: 1.25rem;
    --qalendar-font-xl: 1.5rem
}
//@media (prefers-color-scheme: dark){
//    .calendar-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
//        --qalendar-gray-quite-dark: rgb(221, 221, 221);
//        --qalendar-option-hover: #575757
//    }
//}
.dark{
    .calendar-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
        --qalendar-gray-quite-dark: rgb(221, 221, 221);
        --qalendar-option-hover: #575757
    }
}
[style*="color-scheme: dark"] .calendar-root[data-v-67f77862],[style*="color-scheme: dark"] .date-picker-root[data-v-67f77862]{
    --qalendar-gray-quite-dark: rgb(221, 221, 221);
    --qalendar-option-hover: #575757
}
//@media (prefers-color-scheme: dark){
//    .calendar-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
//        --qalendar-gray-quite-dark: rgb(221, 221, 221)
//    }
//}
.dark{
    .calendar-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root[data-v-67f77862]:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
        --qalendar-gray-quite-dark: rgb(221, 221, 221)
    }
}
[style*="color-scheme: dark"] .calendar-root[data-v-67f77862],[style*="color-scheme: dark"] .date-picker-root[data-v-67f77862]{
    --qalendar-gray-quite-dark: rgb(221, 221, 221)
}
@media (min-width: 62rem){
    .calendar-root[data-v-67f77862]{
        --qalendar-spacing: 20px;
        --qalendar-spacing-half: 10px;
        --qalendar-spacing-double: 40px
    }
}
.date-picker[data-v-67f77862]{
    position:relative;
    width:fit-content;
    min-width:16rem
}
.mode-is-month .date-picker[data-v-67f77862]{
    min-width:8rem
}
.mode-is-day .date-picker[data-v-67f77862]{
    min-width:10rem
}
.qalendar-is-small .date-picker[data-v-67f77862]{
    min-width:initial
}
.date-picker[data-v-67f77862]:not(.is-in-qalendar){
    min-width:initial
}
.qalendar-is-small .date-picker[data-v-67f77862]:not(.is-in-qalendar){
    margin:0 auto
}
.date-picker__value-display[data-v-67f77862]{
    height:36px;
    border-radius:4px;
    padding:0 var(--qalendar-spacing);
    font-size:var(--qalendar-font-m);
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:var(--qalendar-spacing-half);
    user-select:none;
    border:var(--qalendar-border-gray-thin)
}
//@media (prefers-color-scheme: dark){
//    .date-picker__value-display[data-v-67f77862]:not(.is-light-mode .date-picker__value-display){
//        color:var(--qalendar-dark-mode-text-hint);
//        background-color:var(--qalendar-dark-mode-lightly-elevated-surface);
//        border-color:transparent
//    }
//}
.dark{
    .date-picker__value-display[data-v-67f77862]:not(.is-light-mode .date-picker__value-display){
        color:var(--qalendar-dark-mode-text-hint);
        background-color:var(--qalendar-dark-mode-lightly-elevated-surface);
        border-color:transparent
    }
}
[style*="color-scheme: dark"] .date-picker__value-display[data-v-67f77862]{
    color:var(--qalendar-dark-mode-text-hint);
    background-color:var(--qalendar-dark-mode-lightly-elevated-surface);
    border-color:transparent
}
.qalendar-is-small .date-picker__value-display[data-v-67f77862]{
    border:0
}
.date-picker__value-display .date-picker__value-display-text[data-v-67f77862]{
    display:initial
}
.qalendar-is-small .date-picker__value-display .date-picker__value-display-text[data-v-67f77862]{
    display:none
}
.date-picker__value-display svg[data-v-67f77862]{
    font-size:initial
}
.qalendar-is-small .date-picker__value-display svg[data-v-67f77862]{
    font-size:var(--qalendar-font-l)
}
.date-picker__week-picker[data-v-67f77862]{
    padding:var(--qalendar-spacing-half);
    z-index:51;
    background-color:#fff;
    border:var(--qalendar-border-gray-thin);
    border-radius:4px;
    min-width:250px;
    box-shadow:0 2px 4px #f0ececc2
}
//@media (prefers-color-scheme: dark){
//    .date-picker__week-picker[data-v-67f77862]:not(.is-light-mode .date-picker__week-picker){
//        background-color:var(--qalendar-dark-mode-elevated-surface);
//        border-color:transparent;
//        box-shadow:0 2px 4px #0000001a
//    }
//}
.dark{
    .date-picker__week-picker[data-v-67f77862]:not(.is-light-mode .date-picker__week-picker){
        background-color:var(--qalendar-dark-mode-elevated-surface);
        border-color:transparent;
        box-shadow:0 2px 4px #0000001a
    }
}
[style*="color-scheme: dark"] .date-picker__week-picker[data-v-67f77862]{
    background-color:var(--qalendar-dark-mode-elevated-surface);
    border-color:transparent;
    box-shadow:0 2px 4px #0000001a
}
.date-picker__week-picker.is-in-qalendar[data-v-67f77862]{
    top:calc(100% - 1px);
    position:absolute;
    right:0
}
.date-picker__week-picker.is-in-qalendar .mode-is-month[data-v-67f77862],.mode-is-week .date-picker__week-picker.is-in-qalendar[data-v-67f77862]{
    left:50%;
    transform:translate(-50%)
}
.qalendar-is-small .date-picker__week-picker.is-in-qalendar .mode-is-month[data-v-67f77862],.qalendar-is-small .mode-is-week .date-picker__week-picker.is-in-qalendar[data-v-67f77862]{
    left:initial;
    transform:initial
}
.date-picker__week-picker-navigation[data-v-67f77862]{
    font-weight:900;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:var(--qalendar-spacing-half);
    margin-bottom:.25em;
    user-select:none
}
.date-picker__week-picker-navigation .is-icon[data-v-67f77862]{
    transition:var(--qalendar-text-transition);
    color:#131313
}
//@media (prefers-color-scheme: dark){
//    .date-picker__week-picker-navigation .is-icon[data-v-67f77862]:not(.is-light-mode .date-picker__week-picker-navigation .is-icon){
//        color:var(--qalendar-dark-mode-text-hint)
//    }
//}
.dark{
    .date-picker__week-picker-navigation .is-icon[data-v-67f77862]:not(.is-light-mode .date-picker__week-picker-navigation .is-icon){
        color:var(--qalendar-dark-mode-text-hint)
    }
}
[style*="color-scheme: dark"] .date-picker__week-picker-navigation .is-icon[data-v-67f77862]{
    color:var(--qalendar-dark-mode-text-hint)
}
@media (hover: hover){
    .date-picker__week-picker-navigation .is-icon[data-v-67f77862]:hover{
        color:var(--qalendar-blue);
        cursor:pointer
    }
}
.date-picker__toggle-mode[data-v-67f77862]{
    transition:var(--qalendar-text-transition)
}
@media (hover: hover){
    .date-picker__toggle-mode[data-v-67f77862]:hover{
        color:var(--qalendar-blue);
        cursor:pointer
    }
}
.date-picker .months[data-v-67f77862]{
    display:flex;
    flex-wrap:wrap;
    gap:var(--qalendar-spacing-half);
    max-width:20rem
}
.date-picker .months span[data-v-67f77862]{
    padding:4px;
    border:var(--qalendar-border-gray-thin);
    border-radius:2px;
    flex:1 0 33%;
    text-align:center;
    cursor:pointer;
    font-size:var(--qalendar-font-xs);
    transition:all .2s ease
}
@media (hover: hover){
    .date-picker .months span[data-v-67f77862]:hover{
        background-color:var(--qalendar-theme-color);
        color:#fff;
        border:var(--qalendar-border-blue-thin)
    }
}
.date-picker .week[data-v-67f77862]{
    width:100%;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    margin:4px 0
}
.date-picker .week.is-active[data-v-67f77862]{
    //border:1px dashed var(--qalendar-theme-color);
    border-radius:4px
}
.date-picker .week span[data-v-67f77862]{
    display:flex;
    min-height:32px;
    min-width:32px;
    justify-content:center;
    align-items:center;
    flex:1 1 100%;
    cursor:pointer;
    border-radius:50%;
    font-size:var(--qalendar-font-xs)
}
.date-picker .week span.is-weekend[data-v-67f77862]{
    color:gray
}
@media (hover: hover){
    .date-picker .week span.has-day[data-v-67f77862]:hover{
        background-color:var(--qalendar-option-hover)
    }
}
.date-picker .week span.is-today[data-v-67f77862]{
    background-color:var(--qalendar-blue);
    color:#fff
}
.date-picker .week span.is-not-in-month[data-v-67f77862]{
    color:#a9a9a9
}
.date-picker .week span.is-disabled[data-v-67f77862]{
    color:#a9a9a9;
    cursor:not-allowed
}
[data-lang=ar] .date-picker .week span[data-v-67f77862]{
    font-size:.65rem
}
.date-picker__day-names[data-v-67f77862]{
    text-transform:uppercase;
    font-weight:700;
    font-size:var(--qalendar-font-s)
}
.calendar-header[data-v-37f1c389]{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    padding:var(--qalendar-spacing-half);
    border-radius:var(--qalendar-border-radius)
}
@media (min-width: 37.5rem){
    .calendar-header[data-v-37f1c389]{
        justify-content:space-between;
        grid-gap:var(--qalendar-spacing)
    }
}
//@media (prefers-color-scheme: dark){
//    .calendar-header[data-v-37f1c389]:not(.is-light-mode .calendar-header){
//        color:var(--qalendar-dark-mode-text-hint)
//    }
//}
.dark {
    .calendar-header[data-v-37f1c389]:not(.is-light-mode .calendar-header){
        color:var(--qalendar-dark-mode-text-hint)
    }
}
[style*="color-scheme: dark"] .calendar-header[data-v-37f1c389]{
    color:var(--qalendar-dark-mode-text-hint)
}
.calendar-header__period[data-v-37f1c389]{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    gap:var(--qalendar-spacing)
}
.calendar-header__period-name[data-v-37f1c389]{
    font-size:var(--qalendar-font-l);
    text-align:center
}
@media (min-width: 37.5rem){
    .calendar-header__period-name[data-v-37f1c389]{
        margin-bottom:0;
        text-align:left
    }
}
.calendar-header__multiselects[data-v-37f1c389]{
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    gap:var(--qalendar-spacing)
}
.calendar-header__chevron-arrows[data-v-37f1c389]{
    display:flex;
    align-items:center;
    grid-gap:20px
}
.calendar-header__chevron-arrows .calendar-header__chevron-arrow[data-v-37f1c389]{
    cursor:pointer;
    transition:color .2s ease;
    font-size:var(--qalendar-font-m)
}
@media (hover: hover){
    .calendar-header__chevron-arrows .calendar-header__chevron-arrow[data-v-37f1c389]:hover{
        color:var(--qalendar-gray-quite-dark)
    }
}
.calendar-header__mode-picker[data-v-37f1c389]{
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    width:fit-content;
    height:36px;
    border-radius:4px;
    font-size:var(--qalendar-font-m);
    cursor:pointer;
    border:var(--qalendar-border-gray-thin)
}
//@media (prefers-color-scheme: dark){
//    .calendar-header__mode-picker[data-v-37f1c389]:not(.is-light-mode .calendar-header__mode-picker){
//        border-color:transparent
//    }
//}
.dark {
    .calendar-header__mode-picker[data-v-37f1c389]:not(.is-light-mode .calendar-header__mode-picker){
        border-color:transparent
    }
}
[style*="color-scheme: dark"] .calendar-header__mode-picker[data-v-37f1c389]{
    border-color:transparent
}
.calendar-header__mode-picker .calendar-header__mode-value[data-v-37f1c389]{
    padding:0 var(--qalendar-spacing);
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    user-select:none;
    border-radius:4px
}
//@media (prefers-color-scheme: dark){
//    .calendar-header__mode-picker .calendar-header__mode-value[data-v-37f1c389]:not(.is-light-mode .calendar-header__mode-picker .calendar-header__mode-value){
//        background-color:var(--qalendar-dark-mode-lightly-elevated-surface)
//    }
//}
.dark {
    .calendar-header__mode-picker .calendar-header__mode-value[data-v-37f1c389]:not(.is-light-mode .calendar-header__mode-picker .calendar-header__mode-value){
        background-color:var(--qalendar-dark-mode-lightly-elevated-surface)
    }
}
[style*="color-scheme: dark"] .calendar-header__mode-picker .calendar-header__mode-value[data-v-37f1c389]{
    background-color:var(--qalendar-dark-mode-lightly-elevated-surface)
}
.calendar-header__mode-picker .calendar-header__mode-options[data-v-37f1c389]{
    position:absolute;
    z-index:51;
    top:100%;
    left:50%;
    transform:translate(-50%);
    border:var(--qalendar-border-gray-thin);
    background-color:#fff
}
//@media (prefers-color-scheme: dark){
//    .calendar-header__mode-picker .calendar-header__mode-options[data-v-37f1c389]:not(.is-light-mode .calendar-header__mode-picker .calendar-header__mode-options){
//        border-color:transparent;
//        background-color:var(--qalendar-dark-mode-elevated-surface)
//    }
//}
.dark {
    .calendar-header__mode-picker .calendar-header__mode-options[data-v-37f1c389]:not(.is-light-mode .calendar-header__mode-picker .calendar-header__mode-options){
        border-color:transparent;
        background-color:var(--qalendar-dark-mode-elevated-surface)
    }
}
[style*="color-scheme: dark"] .calendar-header__mode-picker .calendar-header__mode-options[data-v-37f1c389]{
    border-color:transparent;
    background-color:var(--qalendar-dark-mode-elevated-surface)
}
.calendar-header__mode-picker .calendar-header__mode-options .calendar-header__mode-option[data-v-37f1c389]{
    padding:var(--qalendar-spacing-half) var(--qalendar-spacing)
}
@media (hover: hover){
    .calendar-header__mode-picker .calendar-header__mode-options .calendar-header__mode-option[data-v-37f1c389]:hover{
        background-color:var(--qalendar-option-hover)
    }
}
.day-timeline[data-v-014bf4e0]{
    position:absolute;
    top:0;
    left:0;
    width:calc(100% + 10px);
    height:var(--11371d19);
    transform:translate(-10px);
    display:flex;
    flex-flow:column;
    justify-content:space-evenly
}
.day-timeline__hour[data-v-014bf4e0]{
    padding-left:4px;
    display:flex;
    flex-flow:column;
    justify-content:flex-start;
    height:100%;
    font-size:clamp(10px,.625rem,14px);
    color:var(--qalendar-gray-quite-dark)
}
.day-timeline__hour-text[data-v-014bf4e0]{
    line-height:0;
    transform:translate(-40px)
}
.day-timeline__hour:first-child .day-timeline__hour-text[data-v-014bf4e0]{
    display:none
}
.day-timeline__hour[data-v-014bf4e0]:not(:last-child){
    border-bottom:var(--qalendar-border-gray-thin)
}
//@media (prefers-color-scheme: dark){
//    .day-timeline__hour[data-v-014bf4e0]:not(:last-child):not(.is-light-mode .day-timeline__hour:not(:last-child)){
//        border-color:var(--qalendar-dark-mode-line-color)
//    }
//}
.dark {
    .day-timeline__hour[data-v-014bf4e0]:not(:last-child):not(.is-light-mode .day-timeline__hour:not(:last-child)){
        border-color:var(--qalendar-dark-mode-line-color)
    }
}
[style*="color-scheme: dark"] .day-timeline__hour[data-v-014bf4e0]:not(:last-child){
    border-color:var(--qalendar-dark-mode-line-color)
}
.week-timeline__event[data-v-778d59fc]{
    position:relative;
    --event-padding: 2px;
    align-items:center;
    height:.9rem;
    width:calc(100% - var(--event-padding));
    font-size:var(--qalendar-font-2xs);
    border-radius:4px;
    padding:var(--event-padding);
    margin-bottom:.25em;
    text-align:left;
    cursor:pointer;
    user-select:none;
    overflow:hidden
}
.week-timeline__event[data-v-778d59fc]:active{
    cursor:not-allowed
}
.week-timeline[data-v-a43746b6]{
    height:fit-content;
    display:flex;
    justify-content:space-evenly;
    padding-bottom:var(--qalendar-spacing-half);
    padding-left:var(--qalendar-week-padding-left);
    border-bottom:var(--qalendar-border-gray-thin)
}
.mode-is-day .week-timeline[data-v-a43746b6]{
    width:100%;
    padding-left:0
}
.week-timeline__day[data-v-a43746b6]{
    width:100%;
    display:flex;
    flex-flow:column;
    align-items:center;
    justify-content:flex-start
}
.week-timeline__day-name[data-v-a43746b6]{
    font-size:var(--qalendar-font-3xs);
    font-weight:400;
    margin-bottom:4px;
    color:var(--qalendar-gray-quite-dark)
}
.week-timeline__date[data-v-a43746b6]{
    font-size:1.2rem;
    margin-bottom:4px;
    height:2rem;
    width:2rem;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center
}
.is-today .week-timeline__date[data-v-a43746b6]{
    background-color:var(--qalendar-blue);
    color:#fff
}
.week-timeline__events[data-v-a43746b6]{
    width:100%;
    flex:1;
    border-right:1px dashed rgb(224,224,224)
}
.week-timeline__day:first-child .week-timeline__events[data-v-a43746b6]{
    border-left:1px dashed rgb(224,224,224)
}
.calendar-week__event[data-v-5564d436]{
    position:absolute;
    width:100%;
    border-radius:4px;
    cursor:pointer;
    box-sizing:content-box;
    user-select:none;
    overflow:hidden
}
.calendar-week__event.is-editable[data-v-5564d436]{
    cursor:grab
}
.calendar-week__event.has-disabled-dnd[data-v-5564d436]{
    cursor:initial
}
.calendar-week__event .calendar-week__event-row[data-v-5564d436]{
    display:flex;
    align-items:flex-start;
    margin-bottom:.2em;
}
.calendar-week__event .calendar-week__event-row p[data-v-5564d436]{
    margin:0;
    padding:0
}
.calendar-week__event .calendar-week__event-info-wrapper[data-v-5564d436]{
    position:relative;
    padding: 3px;
    font-size:var(--qalendar-font-xs);
    height:100%;
    box-sizing:border-box;
    user-select:none;
}
.calendar-week__event .calendar-week__event-blend-out[data-v-5564d436]{
    position:absolute;
    bottom:0;
    height:3px;
    width:100%;
    transform:translate(calc(var(--qalendar-spacing-half) * -1))
}
.calendar-week__event .calendar-week__event-icon[data-v-5564d436]{
    margin:0 4px 0 0;
    font-size:var(--qalendar-font-xs);
}
.calendar-week__event .calendar-week__event-resize[data-v-5564d436]{
    position:absolute;
    width:100%;
    cursor:ns-resize;
    height:5px
}
.calendar-week__event .calendar-week__event-resize-up[data-v-5564d436]{
    top:0
}
.calendar-week__event .calendar-week__event-resize-down[data-v-5564d436]{
    bottom:0
}
.calendar-week__day[data-v-b268be7e]{
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column
}
.calendar-week__day .calendar-week__day-interval[data-v-b268be7e]{
    flex:1;
    font-size:var(--qalendar-font-xs);
    color:var(--qalendar-gray);
    padding:2px
}
.calendar-week__day .calendar-week__day-interval.has-border[data-v-b268be7e]{
    border-bottom:var(--qalendar-border-dashed-gray-thin)
}
.calendar-week__day[data-v-b268be7e]:first-child{
    border-left:1px dashed rgb(224,224,224)
}
//@media (prefers-color-scheme: dark){
//    .calendar-week__day[data-v-b268be7e]:first-child:not(.is-light-mode .calendar-week__day:first-child){
//        border-color:var(--qalendar-dark-mode-line-color)
//    }
//}
.dark {
    .calendar-week__day[data-v-b268be7e]:first-child:not(.is-light-mode .calendar-week__day:first-child){
        border-color:var(--qalendar-dark-mode-line-color)
    }
}
[style*="color-scheme: dark"] .calendar-week__day[data-v-b268be7e]:first-child{
    border-color:var(--qalendar-dark-mode-line-color)
}
.calendar-week__day[data-v-b268be7e]:not(:last-child){
    border-right:1px dashed rgb(224,224,224)
}
//@media (prefers-color-scheme: dark){
//    .calendar-week__day[data-v-b268be7e]:not(:last-child):not(.is-light-mode .calendar-week__day:not(:last-child)){
//        border-color:var(--qalendar-dark-mode-line-color)
//    }
//}
.dark {
    .calendar-week__day[data-v-b268be7e]:not(:last-child):not(.is-light-mode .calendar-week__day:not(:last-child)){
        border-color:var(--qalendar-dark-mode-line-color)
    }
}
[style*="color-scheme: dark"] .calendar-week__day[data-v-b268be7e]:not(:last-child){
    border-color:var(--qalendar-dark-mode-line-color)
}
.event-flyout[data-v-d6a61e37]{
    position:fixed;
    z-index:50;
    background-color:#fff;
    max-height:100%;
    width:var(--7c0b2114);
    max-width:98%;
    border:var(--qalendar-border-gray-thin);
    border-radius:8px;
    box-shadow:0 12px 24px #00000017,0 6px 12px #0000002e;
    overflow:hidden;
    transition:all .2s ease;
    transition-property:opacity,transform;
    transform:translateY(-40px);
    opacity:0;
    pointer-events:none
}
//@media (prefers-color-scheme: dark){
//    .event-flyout[data-v-d6a61e37]:not(.is-light-mode .event-flyout){
//        background-color:var(--qalendar-dark-mode-elevated-surface);
//        border-color:transparent
//    }
//}
.dark {
    .event-flyout[data-v-d6a61e37]:not(.is-light-mode .event-flyout){
        background-color:var(--qalendar-dark-mode-elevated-surface);
        border-color:transparent
    }
}
[style*="color-scheme: dark"] .event-flyout[data-v-d6a61e37]{
    background-color:var(--qalendar-dark-mode-elevated-surface);
    border-color:transparent
}
.event-flyout.is-visible[data-v-d6a61e37]{
    opacity:1;
    transform:translateY(0);
    pointer-events:initial
}
.event-flyout__relative-wrapper[data-v-d6a61e37]{
    position:relative
}
.event-flyout__menu[data-v-d6a61e37]{
    display:flex;
    justify-content:space-between;
    align-items:center
}
.event-flyout__menu .event-flyout__menu-editable[data-v-d6a61e37],.event-flyout__menu .event-flyout__menu-close[data-v-d6a61e37]{
    padding:5px 7px;
    display:flex;
    grid-gap:20px
}
.is-not-editable .event-flyout__menu .event-flyout__menu-close[data-v-d6a61e37]{
    position:absolute;
    top:0;
    right:0
}
.event-flyout__menu-item[data-v-d6a61e37]{
    font-size:var(--qalendar-font-l);
    color:gray
}
//@media (prefers-color-scheme: dark){
//    .event-flyout__menu-item[data-v-d6a61e37]:not(.is-light-mode .event-flyout__menu-item){
//        color:var(--qalendar-dark-mode-text-hint)
//    }
//}
.dark {
    .event-flyout__menu-item[data-v-d6a61e37]:not(.is-light-mode .event-flyout__menu-item){
        color:var(--qalendar-dark-mode-text-hint)
    }
}
[style*="color-scheme: dark"] .event-flyout__menu-item[data-v-d6a61e37]{
    color:var(--qalendar-dark-mode-text-hint)
}
.event-flyout__menu-item[data-v-d6a61e37]:hover{
    color:var(--qalendar-theme-color);
    cursor:pointer
}
.event-flyout .is-trash-icon[data-v-d6a61e37]:hover{
    color:red
}
.event-flyout__info-wrapper[data-v-d6a61e37]{
    padding: 10px;
}
.event-flyout__row[data-v-d6a61e37]{
    display:flex;
    grid-gap: 7px;
    margin-bottom: 9px;
    font-weight:400
}
.event-flyout__row p[data-v-d6a61e37]{
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    gap: 8px;
    span{
        font-size: 14px;
        strong{
            font-size: 14px;
            font-weight: 600;
        }
        a{
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
.event-flyout__row svg[data-v-d6a61e37]{
    margin-top:.1rem;
    color:#5f6368;
    width:14px;
    display: none;;
}
//@media (prefers-color-scheme: dark){
//    .event-flyout__row svg[data-v-d6a61e37]:not(.is-light-mode .event-flyout__row svg){
//        color:var(--qalendar-dark-mode-text-hint)
//    }
//}
.dark {
    .event-flyout__row svg[data-v-d6a61e37]:not(.is-light-mode .event-flyout__row svg){
        color:var(--qalendar-dark-mode-text-hint)
    }
}
[style*="color-scheme: dark"] .event-flyout__row svg[data-v-d6a61e37]{
    color:var(--qalendar-dark-mode-text-hint)
}
.event-flyout__color-icon{
    --icon-height: 16px;
    border-radius:50%;
    height:var(--icon-height);
    width:var(--icon-height);
    display: none;
}
.event-flyout .is-title{
    font-size: 14px;
    align-items:center;
    line-height: 1.3;
    display: none;
}
.is-not-editable .event-flyout .is-title[data-v-d6a61e37]{
    max-width:90%
}
.event-flyout .is-time[data-v-d6a61e37]{
    font-size: 12px;
    margin-bottom:.75em;
    display: none;
}
.calendar-week__wrapper[data-v-8bb6ad05]{
    position:relative;
    padding-left:var(--qalendar-week-padding-left);
    overflow-y:auto
}
.calendar-week[data-v-8bb6ad05]{
    position:relative;
    width:100%;
    flex:1 1 auto
}
.calendar-week__events[data-v-8bb6ad05]{
    display:flex;
    width:100%;
    height:var(--5cb5d057);
    overflow:hidden
}
.calendar-week .current-time-line[data-v-8bb6ad05]{
    position:absolute;
    left:0;
    width:100%;
    height:1px;
    z-index:1;
    background-color:red
}
.calendar-week .current-time-line__circle[data-v-8bb6ad05]{
    position:relative
}
.calendar-week .current-time-line__circle[data-v-8bb6ad05]:before{
    content:"";
    position:absolute;
    transform:translate(-45%,-45%);
    width:10px;
    height:10px;
    border-radius:50%;
    background-color:red
}
.calendar-week .custom-current-time[data-v-8bb6ad05]{
    position:absolute;
    left:0;
    width:100%;
    z-index:1
}
.calendar-month__event[data-v-4766dfc7]{
    --event-inline-padding: 4px;
    display:flex;
    align-items:center;
    overflow:hidden;
    border-radius:4px;
    font-size:var(--qalendar-font-2xs);
    width:calc(100% - calc(var(--event-inline-padding) * 2));
    margin-bottom:4px;
    padding:.25rem var(--event-inline-padding);
    cursor:pointer;
    user-select:none
}
.calendar-month__event .calendar-month__event-time[data-v-4766dfc7]{
    margin-right:6px;
    display: none;
}
.calendar-month__event .calendar-month__event-time[data-v-4766dfc7],.calendar-month__event .calendar-month__event-title[data-v-4766dfc7],.calendar-month__event .calendar-month__event-color[data-v-4766dfc7]{
    flex-shrink:0
}
.qalendar-is-small .calendar-month__event .calendar-month__event-time[data-v-4766dfc7],.qalendar-is-small .calendar-month__event .calendar-month__event-title[data-v-4766dfc7]{
    display:none
}
.qalendar-is-small .calendar-month__event[data-v-4766dfc7]{
    background-color:var(--63a9bc8a);
    width:4px;
    height:4px;
    border-radius:50%;
    padding:1px;
    margin-right:1px
}
.calendar-month__event.is-draggable[data-v-4766dfc7]{
    cursor:grab
}
.calendar-month__event[data-v-4766dfc7]:active{
    z-index:100
}
.calendar-month__event[data-v-4766dfc7]:not(.is-draggable):active{
    cursor:not-allowed
}
@media (hover: hover){
    .calendar-month__event[data-v-4766dfc7]:hover{
        background-color:var(--qalendar-light-gray)
    }
}
@media (hover: hover) and (hover: hover){
    .calendar-month__event[data-v-4766dfc7]:hover:hover{
        background-color:var(--qalendar-option-hover)
    }
}
.calendar-month__event .calendar-month__event-color[data-v-4766dfc7]{
    background-color:var(--63a9bc8a);
    width:6px;
    height:6px;
    border-radius:50%;
    margin-right:4px
}
.calendar-month__weekday[data-v-034f06d8]{
    height:100%;
    flex:1;
    display:flex;
    flex-flow:column;
    align-items:center;
    border-right:var(--qalendar-border-gray-thin);
    border-bottom:var(--qalendar-border-gray-thin);
    overflow:hidden;
    transition:background-color .2s ease-in-out
}
//@media (prefers-color-scheme: dark){
//    .calendar-month__weekday[data-v-034f06d8]:not(.is-light-mode .calendar-month__weekday){
//        border-color:var(--qalendar-dark-mode-line-color)
//    }
//}
.dark {
    .calendar-month__weekday[data-v-034f06d8]:not(.is-light-mode .calendar-month__weekday){
        border-color:var(--qalendar-dark-mode-line-color)
    }
}
[style*="color-scheme: dark"] .calendar-month__weekday[data-v-034f06d8]{
    border-color:var(--qalendar-dark-mode-line-color)
}
.calendar-month__weekday.is-droppable[data-v-034f06d8]{
    background-color:var(--qalendar-light-gray)
}
.calendar-month__weekday[data-v-034f06d8]:last-child{
    border-right:0
}
.qalendar-is-small .calendar-month__weekday.is-selected[data-v-034f06d8]{
    -webkit-box-shadow:inset 0 0 0 3px var(--qalendar-theme-color);
    -moz-box-shadow:inset 0 0 0 3px var(--qalendar-theme-color);
    box-shadow:inset 0 0 0 3px var(--qalendar-theme-color);
    border-radius:5px
}
.qalendar-is-small .calendar-month__weekday[data-v-034f06d8]{
    height:45px;
    width:45px;
    display:flex;
    flex-flow:column;
    justify-content:space-around
}
.calendar-month__weekday .calendar-month__day-date[data-v-034f06d8]{
    font-size:var(--qalendar-font-xs);
    color:var(--qalendar-gray-quite-dark)
}
.calendar-month__weekday .calendar-month__day-date[data-v-034f06d8]:first-child{
    margin-top:6px
}
.calendar-month__weekday .calendar-month__weekday-more[data-v-034f06d8]{
    font-size:var(--qalendar-font-2xs);
    width:100%;
    padding-left:4px;
    color:var(--qalendar-gray-quite-dark);
    cursor:pointer
}
.calendar-month__weekday .calendar-month_events[data-v-034f06d8]{
    width:100%
}
.qalendar-is-small .calendar-month__weekday .calendar-month_events[data-v-034f06d8]{
    display:flex;
    flex-flow:row;
    justify-content:center
}
.qalendar-is-small .calendar-month__weekday .calendar-month_events .calendar-month__weekday-more[data-v-034f06d8]{
    display:none
}
.calendar-month__weekday.is-today .calendar-month__day-date[data-v-034f06d8]{
    background-color:var(--qalendar-theme-color);
    color:#fff;
    border-radius:50%;
    padding:4px 6px
}
.qalendar-is-small .calendar-month__weekday.is-today .calendar-month__day-date[data-v-034f06d8]{
    padding:2px 4px
}
.space-reserver[data-v-034f06d8]{
    height:100%;
    flex:1;
    display:flex;
    flex-flow:column;
    align-items:center;
    border-right:var(--qalendar-border-gray-thin);
    border-bottom:var(--qalendar-border-gray-thin);
    border-right-color:transparent
}
//@media (prefers-color-scheme: dark){
//    .space-reserver[data-v-034f06d8]:not(.is-light-mode .space-reserver){
//        border-color:var(--qalendar-dark-mode-line-color)
//    }
//}
.dark {
    .space-reserver[data-v-034f06d8]:not(.is-light-mode .space-reserver){
        border-color:var(--qalendar-dark-mode-line-color)
    }
}
[style*="color-scheme: dark"] .space-reserver[data-v-034f06d8]{
    border-color:var(--qalendar-dark-mode-line-color)
}
.space-reserver+.calendar-month__weekday[data-v-034f06d8]:not(.trailing-or-leading){
    border-left:var(--qalendar-border-gray-thin)
}
.calendar-month__day-name[data-v-09c7c5db]{
    display:block;
    font-size:12px;
    font-weight:500;
    color:var(--qalendar-gray-quite-dark);
    margin-bottom:var(--qalendar-spacing-half)
}
.agenda__event[data-v-698b431e]{
    background-color:var(--691f2791);
    color:var(--fa1649c2);
    display:flex;
    flex-flow:column;
    justify-content:flex-start;
    border-radius:4px;
    font-size:var(--qalendar-font-2xs);
    margin-bottom:4px;
    padding:var(--qalendar-spacing);
    cursor:pointer;
    user-select:none
}
.agenda__wrapper[data-v-b60cbce5]{
    display:flex;
    flex-flow:row;
    padding:10px 5px 0
}
.agenda__wrapper .agenda__header[data-v-b60cbce5]{
    padding-right:10px
}
.agenda__wrapper .agenda__header .agenda__header-day-name[data-v-b60cbce5]{
    text-align:center;
    color:var(--qalendar-theme-color);
    font-size:var(--qalendar-font-xs);
    font-weight:700
}
.agenda__wrapper .agenda__header .agenda__header-date[data-v-b60cbce5]{
    height:fit-content;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5px;
    border-radius:4px;
    background-color:var(--qalendar-theme-color);
    color:#fff;
    font-weight:600;
    font-size:var(--qalendar-font-m)
}
.agenda__wrapper .agenda__content[data-v-b60cbce5]{
    display:flex;
    flex-flow:column;
    width:100%;
    height:auto
}
.agenda__wrapper .agenda__content .is-empty[data-v-b60cbce5]{
    min-height:70px;
    display:flex;
    justify-content:center;
    align-items:center
}
.calendar-month[data-v-206c177e]{
    position:relative;
    display:flex;
    flex-flow:column;
    width:100%;
    height:100%;
    overflow-y:auto
}
.qalendar-is-small .calendar-month[data-v-206c177e]{
    height:initial
}
.calendar-month .calendar-month__week-day-names[data-v-206c177e]{
    display:flex;
    justify-content:space-between
}
.calendar-month .calendar-month__week-day-names .calendar-month__week-day-name[data-v-206c177e]{
    flex:1;
    text-align:center
}
.calendar-month .calendar-month__weeks[data-v-206c177e]{
    height:100%;
    flex-grow:1;
    display:flex;
    flex-flow:column;
    justify-content:space-between
}
.calendar-month .calendar-month__week[data-v-206c177e]{
    display:flex;
    flex:1
}
.calendar-month .calendar-month__week[data-v-206c177e]:first-child{
    border-top:var(--qalendar-border-gray-thin)
}
//@media (prefers-color-scheme: dark){
//    .calendar-month .calendar-month__week[data-v-206c177e]:first-child:not(.is-light-mode .calendar-month .calendar-month__week:first-child){
//        border-color:var(--qalendar-dark-mode-line-color)
//    }
//}
.dark {
    .calendar-month .calendar-month__week[data-v-206c177e]:first-child:not(.is-light-mode .calendar-month .calendar-month__week:first-child){
        border-color:var(--qalendar-dark-mode-line-color)
    }
}
[style*="color-scheme: dark"] .calendar-month .calendar-month__week[data-v-206c177e]:first-child{
    border-color:var(--qalendar-dark-mode-line-color)
}
.calendar-month .calendar-month__day_events[data-v-206c177e]{
    height:100%;
    display:none
}
.qalendar-is-small .calendar-month .calendar-month__day_events[data-v-206c177e]{
    display:block
}
.ps{
    overflow:hidden!important;
    overflow-anchor:none;
    -ms-overflow-style:none;
    touch-action:auto;
    -ms-touch-action:auto
}
.ps__rail-x{
    display:none;
    opacity:0;
    transition:background-color .2s linear,opacity .2s linear;
    -webkit-transition:background-color .2s linear,opacity .2s linear;
    height:15px;
    bottom:0px;
    position:absolute
}
.ps__rail-y{
    display:none;
    opacity:0;
    transition:background-color .2s linear,opacity .2s linear;
    -webkit-transition:background-color .2s linear,opacity .2s linear;
    width:15px;
    right:0;
    position:absolute
}
.ps--active-x>.ps__rail-x,.ps--active-y>.ps__rail-y{
    display:block;
    background-color:transparent
}
.ps:hover>.ps__rail-x,.ps:hover>.ps__rail-y,.ps--focus>.ps__rail-x,.ps--focus>.ps__rail-y,.ps--scrolling-x>.ps__rail-x,.ps--scrolling-y>.ps__rail-y{
    opacity:.6
}
.ps .ps__rail-x:hover,.ps .ps__rail-y:hover,.ps .ps__rail-x:focus,.ps .ps__rail-y:focus,.ps .ps__rail-x.ps--clicking,.ps .ps__rail-y.ps--clicking{
    background-color:#eee;
    opacity:.9
}
.ps__thumb-x{
    background-color:#aaa;
    border-radius:6px;
    transition:background-color .2s linear,height .2s ease-in-out;
    -webkit-transition:background-color .2s linear,height .2s ease-in-out;
    height:6px;
    bottom:2px;
    position:absolute
}
.ps__thumb-y{
    background-color:#aaa;
    border-radius:6px;
    transition:background-color .2s linear,width .2s ease-in-out;
    -webkit-transition:background-color .2s linear,width .2s ease-in-out;
    width:6px;
    right:2px;
    position:absolute
}
.ps__rail-x:hover>.ps__thumb-x,.ps__rail-x:focus>.ps__thumb-x,.ps__rail-x.ps--clicking .ps__thumb-x{
    background-color:#999;
    height:11px
}
.ps__rail-y:hover>.ps__thumb-y,.ps__rail-y:focus>.ps__thumb-y,.ps__rail-y.ps--clicking .ps__thumb-y{
    background-color:#999;
    width:11px
}
@supports (-ms-overflow-style: none){
    .ps{
        overflow:auto!important
    }
}
@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none){
    .ps{
        overflow:auto!important
    }
}
.calendar-root,.date-picker-root{
    --qalendar-blue: rgb(38 132 255);
    --qalendar-blue-transparent: rgba(38 132 255 / 90%);
    --qalendar-gray-quite-dark: rgb(110 110 110);
    --qalendar-gray: rgb(180 180 180);
    --qalendar-green: rgb(51 182 121);
    --qalendar-theme-color: var(--qalendar-blue);
    --qalendar-light-gray: rgba(240 236 236 / 76%);
    --qalendar-option-hover: var(--qalendar-light-gray);
    --qalendar-dark-mode-elevated-surface: #383838;
    --qalendar-dark-mode-lightly-elevated-surface: #2e2e2e;
    --qalendar-dark-mode-text-primary: rgba(255 255 255 1);
    --qalendar-dark-mode-text-secondary: rgba(255 255 255 .7);
    --qalendar-dark-mode-text-hint: rgba(255 255 255 .5);
    --qalendar-dark-mode-line-color: var(--qalendar-gray);
    --qalendar-border-gray-thin: 1px solid rgb(224 224 224);
    --qalendar-border-dashed-gray-thin: 1px dashed rgb(224 224 224);
    --qalendar-border-blue-thin: 1px solid var(--qalendar-theme-color);
    --qalendar-border-radius: 8px;
    --qalendar-spacing: 10px;
    --qalendar-spacing-half: 5px;
    --qalendar-spacing-double: 20px;
    --qalendar-box-shadow: 0 4px 4px rgba(0 0 0 / 6%), 0 1px 4px rgba(0 0 0 / 18%);
    --qalendar-text-transition: color .2s ease;
    --qalendar-week-padding-left: 56px;
    --qalendar-font-3xs: .625rem;
    --qalendar-font-2xs: .688rem;
    --qalendar-font-xs: .75rem;
    --qalendar-font-s: .875rem;
    --qalendar-font-m: 1rem;
    --qalendar-font-l: 1.25rem;
    --qalendar-font-xl: 1.5rem
}
//@media (prefers-color-scheme: dark){
//    .calendar-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
//        --qalendar-gray-quite-dark: rgb(221, 221, 221);
//        --qalendar-option-hover: #575757
//    }
//}
.dark {
    .calendar-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
        --qalendar-gray-quite-dark: rgb(221, 221, 221);
        --qalendar-option-hover: #575757
    }
}
[style*="color-scheme: dark"] .calendar-root,[style*="color-scheme: dark"] .date-picker-root{
    --qalendar-gray-quite-dark: rgb(221, 221, 221);
    --qalendar-option-hover: #575757
}
//@media (prefers-color-scheme: dark){
//    .calendar-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
//        --qalendar-gray-quite-dark: rgb(221, 221, 221)
//    }
//}
.dark {
    .calendar-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root),.date-picker-root:not(.is-light-mode .calendar-root,.is-light-mode .date-picker-root){
        --qalendar-gray-quite-dark: rgb(221, 221, 221)
    }
}
[style*="color-scheme: dark"] .calendar-root,[style*="color-scheme: dark"] .date-picker-root{
    --qalendar-gray-quite-dark: rgb(221, 221, 221)
}
@media (min-width: 62rem){
    .calendar-root{
        --qalendar-spacing: 20px;
        --qalendar-spacing-half: 10px;
        --qalendar-spacing-double: 40px
    }
}
.calendar-root-wrapper{
    width:100%;
    max-width:100vw;
    height:100%;
    display:flex
}
.calendar-root-wrapper .calendar-root{
    flex:1;
    border:var(--qalendar-border-gray-thin);
    border-radius:var(--qalendar-border-radius);
    font-family:var(--51d82805);
    position:relative;
    width:100%;
    margin:0 auto;
    display:flex;
    flex-flow:column
}
//@media (prefers-color-scheme: dark){
//    .calendar-root-wrapper .calendar-root:not(.is-light-mode .calendar-root-wrapper .calendar-root){
//        background:#121212;
//        color:#fff;
//        border-color:transparent
//    }
//}
.dark {
    .calendar-root-wrapper .calendar-root:not(.is-light-mode .calendar-root-wrapper .calendar-root){
        background:#121212;
        color:#fff;
        border-color:transparent
    }
}
[style*="color-scheme: dark"] .calendar-root-wrapper .calendar-root{
    background:#121212;
    color:#fff;
    border-color:transparent
}
.calendar-root-wrapper .calendar-root .top-bar-loader{
    position:absolute;
    top:1px;
    left:2px;
    width:calc(100% - 4px);
    height:3px;
    background:rgba(241,241,241,.2);
    border-radius:16px;
    overflow:hidden
}
.calendar-root-wrapper .calendar-root .top-bar-loader:before{
    content:"";
    height:4px;
    width:calc(100% - 4px);
    position:absolute;
    top:1px;
    left:2px;
    background:rgb(38,132,255);
    background:linear-gradient(90deg,rgb(38,132,255) 0%,rgba(38,132,255,.5088410364) 48%,rgb(38,132,255) 100%);
    animation:load 1.8s infinite;
    border-radius:16px
}
//@media (prefers-color-scheme: dark){
//    .calendar-root-wrapper .calendar-root .top-bar-loader:before:not(.is-light-mode .calendar-root-wrapper .calendar-root .top-bar-loader:before){
//        background:rgb(229,224,245)
//    }
//}
.dark {
    .calendar-root-wrapper .calendar-root .top-bar-loader:before:not(.is-light-mode .calendar-root-wrapper .calendar-root .top-bar-loader:before){
        background:rgb(229,224,245)
    }
}
[style*="color-scheme: dark"] .calendar-root-wrapper .calendar-root .top-bar-loader:before{
    background:rgb(229,224,245)
}
@keyframes load{
    0%{
        width:0;
        left:-100%
    }
    50%{
        left:0;
        width:100%
    }
    to{
        width:0;
        left:100%
    }
}
.calendar-root-wrapper .calendar-root .loading-enter-active,.calendar-root-wrapper .calendar-root .loading-leave-active{
    transition:background .5s ease
}
.calendar-root-wrapper .calendar-root .loading-leave-to,.calendar-root-wrapper .calendar-root .loading-enter-from{
    background-color:#fff0
}

.calendar-container{
    background-color: #ffffff;
    border-radius: 0.5rem;
    @media (min-width: 800px) {
        height: calc(100vh - 10em);
    }
    .calendar-root-wrapper{
        .calendar-root{
            border: none;
        }
    }
}
